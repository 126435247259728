import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import obiWan from '../images/404bg.jpg'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Not found" />
    <section style={{ backgroundImage: "url(" + obiWan + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="container">
        <div
          className="is-flex is-flex-direction-column is-justify-content-center"
          style={{ minHeight: "60vh" }}
        >
          <h1 className="title is-size-1 has-text-danger">404 Not Found</h1>
          <p className="is-size-3 has-text-grey-dark">
            This is not the page you're looking for
          </p>
          <Link className="button is-danger is-outlined" to="/" style={{ alignSelf: 'flex-start', marginTop: '2rem' }}>Head Home <FaArrowRight className="ml-4" /></Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
